<template>
<section class="domain_content">
    <p>
        Welcome to our curated list of trending WhatsApp Mods!
    </p>
    <p>
        This page highlights the most popular modified versions of WhatsApp, offering enhanced features and customization options that go beyond the standard app.
    </p>
    <p>
        Explore the top mods, such as GB WhatsApp, WhatsApp Plus, and WhatsApp Gold, and discover how they can transform the way you connect and communicate.
    </p>

    <ModList :modList="modList" />

</section>
</template>

<script>
import '@/css/default.scss';
import {
    mapGetters
} from 'vuex';
import ModList from '@/components/ModList.vue';


export default {
    ...{
        "metaInfo": {
            "title": "GB WhatsApp APK Download Page with Anti-Ban Enhanced",
            "meta": [{
                    "name": "description",
                    "content": "Download GB WhatsApp APK (Anti-Ban) New Version for Android"
                }, {
                    "name": "title",
                    "content": "GB WhatsApp APK Download Page with Anti-Ban Enhanced"
                }, {
                    "name": "keywords",
                    "content": "gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download"
                },
                {
                    "property": "og:title",
                    "content": "GB WhatsApp APK Download Page with Anti-Ban Enhanced"
                }, {
                    "property": "og:description",
                    "content": "Download GB WhatsApp APK (Anti-Ban) New Version for Android"
                }
            ],
            "link": [{
                "rel": "canonical",
                "href": "https://whatsapppro.org/more-mods/"
            }]
        }
    },
    components: {
        ModList
    },
    data() {
        return {
            from: null,
            filename: null,
            partners: null,
            updateTime: null,
            clicks: null,
            pageIntSuccess: null,
            apk: null,
            modList: [{
                    title: "JT WhatsApp APK",
                    url: "/jtwhatsapp-introduction",
                    img: require('@/assets/mod-icon-5.webp'),
                    alt: "JT WhatsApp APK v10.2",
                    version: "v10.2"
                },
                {
                    title: "Gold WhatsApp APK",
                    url: "/download-gold-whatsapp",
                    img: require('@/assets/mod-icon-11.webp'),
                    alt: "Gold WhatsApp APK v5.15",
                    version: "v5.15"
                },
                {
                    title: "GB WhatsApp Pro APK",
                    url: "/download-gb-whatsapp-pro", 
                    img: require('@/assets/gbwa-pro.webp'), 
                    alt: "GB WhatsApp Pro APK v18.20",
                    version: "v18.20"
                }
            ]

        };
    },
    computed: {
        ...mapGetters([
            'showDebug',
            'host',
            "downloadicon"
        ])
    },
    created() {
        this.initParams();
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        download(apk) {
            this.$global.download(apk);
        },
        goback() {
            this.$router.push('/');
        },
        initParams() {
            this.from = this.$global.GetQueryString('from');
            this.filename = this.$global.GetQueryString('filename');
        },
        fetchData() {
            if (this.from) {
                this.getAPK(true, this.from, this.filename);
            } else {
                this.getAPK();
                this.getOfficialApk();
            }
        },
        updateAPK() {
            this.$emit('update-apk', this.apk);
        },
        getAPK(isFromQuery = false, from = 'gb', filename = 'gb') {
            this.$server
                .getAPPInfo({
                    promotionChannel: from,
                    promotionName: filename
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.partners = res.data.partners;
                        this.updateTime = res.data.updateTime;
                        this.clicks = res.data.clicks;
                        this.pageIntSuccess = true;
                        if (isFromQuery) {
                            this.apk = res.data.apk;
                            this.updateAPK();
                        }
                    }
                })
                .catch((err) => {
                    console.error('Error fetching APK:', err);
                });
        },
        getOfficialApk() {
            this.$server
                .getOfficialApk({
                    domain: 'gbpro.download',
                    appName: 'GB_gbpro.download'
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.apk = res.data.apk;
                        this.updateAPK();
                        console.log('APK fetched successfully');
                    }
                })
                .catch((err) => {
                    console.error('Error fetching official APK:', err);
                });
        }
    }
};
</script>
